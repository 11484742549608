import './bootstrap/stage-1';

const elmBrowser = document.getElementById('jw-browser-outdated');
const elmRoot = document.getElementById('jw-dash-root');

// No need for the outdated browsers notification when
// we managed to load our modern bundle
elmBrowser && elmBrowser.remove();

if (elmRoot) {
  // We managed to load the modern bundle, so we can display our app
  elmRoot.style.display = 'flex';
}
